<template>
  <v-card outlined class="summary-widget h100">
    <div v-if="loading" class="d-flex align-center justify-center h100">
      <v-progress-circular
        :size="summaryWidgetSpinnerSize"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-column justify-space-between h100">
      <p v-if="data.capacity" class="widget-title widget-header">
        {{ data.capacity.title }}
      </p>
      <div v-if="data.capacity" class="d-flex align-center flex-wrap">
        <h3 class="mr-2">
          {{ data.capacity.mw ? data.capacity.mw.toLocaleString() : 0 }}
          MW
        </h3>
      </div>
      <p class="widget-title widget-footer flex-wrap">
        <span v-if="data.capacity">
          <span v-if="data.capacity.nOrgs > 0">
            {{ data.capacity.nOrgs ? data.capacity.nOrgs.toLocaleString() : 0 }}
            org{{ data.capacity.nSites === 1 ? "" : "s" }},
          </span>
          <span v-if="data.capacity.nSites > 0">
            {{
              data.capacity.nSites ? data.capacity.nSites.toLocaleString() : 0
            }}
            site{{ data.capacity.nSites === 1 ? "" : "s" }},
          </span>

          <span
            v-for="(model, index) in turbineModels"
            :key="index"
            class="d-flex"
          >
            <span v-if="model.count > 0">
              {{ model.count ? model.count.toLocaleString() : 0 }}
              <span v-if="turbineModels.length > 0">
                {{ model.value }}
              </span>
              turbine{{ model.count === 1 ? "" : "s" }}
            </span>
          </span>
          <span
            v-if="data.capacity.nTurbines > 0 && turbineModels.length === 0"
          >
            {{
              data.capacity.nTurbines
                ? data.capacity.nTurbines.toLocaleString()
                : 0
            }}
            turbine{{ data.capacity.nTurbines === 1 ? "" : "s"
            }}<span v-if="data.capacity.nOems > 0">,</span>
          </span>

          <span v-if="data.capacity.nOems > 0">
            {{ data.capacity.nOems ? data.capacity.nOems.toLocaleString() : 0 }}
            OEM{{ data.capacity.nOems === 1 ? "" : "s" }}
          </span>
        </span>
      </p>
    </div>
  </v-card>
</template>

<script>
import { summaryWidgetSpinnerSize } from "@/helpers/variables";

export default {
  name: "CapacityCard",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    turbineModels: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      summaryWidgetSpinnerSize,
    };
  },
};
</script>
