var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('div',{staticClass:"d-flex flex-wrap align-center mr-3"},[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"org_table_search","value":_vm.searchText},on:{"input":_vm.updateSearch}})],1)]),_c('div',{staticClass:"mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"org_table"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('div',[_c('DataTable',{attrs:{"className":"no-background org-table","itemKey":"org_id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.searchText,"showSelect":false,"headers":_vm.mutableTableHeaders,"items":_vm.data,"page":_vm.page,"itemsPerPage":_vm.itemsPerPage,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[(_vm.checkColumn('name'))?_c('td',[_c('span',[_c('router-link',{staticClass:"orgname-link",attrs:{"to":{
                    name: 'OrgDashboard',
                    params: {
                      orgId: item.organization_id,
                    },
                  }}},[_vm._v(_vm._s(item.name))])],1)]):_vm._e(),(_vm.checkColumn('site_names'))?_c('td',{staticClass:"d-flex align-center flex-wrap"},_vm._l((item.site_names),function(site,index){return _c('div',{key:site.id},[_c('div',{staticClass:"org-sites",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(site)),(index + 1 < item.site_names.length)?_c('span',[_vm._v(", ")]):_vm._e()])])}),0):_vm._e(),(_vm.checkColumn('n_open'))?_c('td',[(item.n_open > 0)?_c('router-link',{staticClass:"has-issue error--text card-status-link lighten-error-color",attrs:{"to":_vm.generateLink(item.organization_id, 'open')}},[_vm._v(_vm._s(item.n_open))]):_c('span',{staticClass:"no-issue"},[_vm._v("0")])],1):_vm._e(),(_vm.checkColumn('n_require_external_input'))?_c('td',[(item.n_require_external_input > 0)?_c('router-link',{staticClass:"has-issue error--text card-status-link lighten-error-color",attrs:{"to":_vm.generateLink(item.organization_id, 'open')}},[_vm._v(_vm._s(item.n_require_external_input))]):_c('span',{staticClass:"no-issue"},[_vm._v("0")])],1):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }