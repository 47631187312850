var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('div',{staticClass:"d-flex flex-wrap align-center mr-3"},[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","value":_vm.searchText,"clearable":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"site_table_search"},on:{"input":_vm.updateSearch}})],1)]),_c('div',{staticClass:"mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"site_table"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('div',[_c('DataTable',{attrs:{"className":"no-background sites-table","itemKey":"site_id","search":_vm.searchText,"showSelect":false,"headers":_vm.mutableTableHeaders,"items":_vm.data,"itemsPerPage":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[(_vm.checkColumn('site_name'))?_c('td',[_c('div',{staticClass:"site-name"},[_c('router-link',{staticClass:"sitename-link",attrs:{"to":{
                    name: 'SiteDashboard',
                    params: {
                      orgId: item.org_id,
                      siteId: item.farm_id,
                    },
                  }}},[_vm._v(_vm._s(item.site_name))])],1)]):_vm._e(),(_vm.checkColumn('subscription'))?_c('td',[_c('span',{staticClass:"subscription"},[_vm._v(_vm._s(item.subscription))])]):_vm._e(),(_vm.checkColumn('turbines'))?_c('td',[_vm._v(_vm._s(item.turbines))]):_vm._e(),(_vm.checkColumn('turbine_models'))?_c('td',[_c('span',{staticClass:"models"},[_vm._v(_vm._s(item.turbine_models.join(", ")))])]):_vm._e(),(_vm.checkColumn('n_open'))?_c('td',[(item.n_open > 0)?_c('router-link',{staticClass:"has-issue error--text card-status-link lighten-error-color",attrs:{"to":_vm.generateLink(item.organization_id, item.farm_id, 'open')}},[_vm._v(_vm._s(item.n_open))]):_c('span',{staticClass:"no-issue"},[_vm._v("0")])],1):_vm._e(),(_vm.checkColumn('n_req_attn'))?_c('td',[(item.n_req_attn > 0)?_c('router-link',{staticClass:"has-issue error--text card-status-link lighten-error-color",attrs:{"to":_vm.generateLink(item.organization_id, item.farm_id, 'open')}},[_vm._v(_vm._s(item.n_req_attn))]):_c('span',{staticClass:"no-issue"},[_vm._v("0")])],1):_vm._e(),(_vm.checkColumn('n_unconfirmed'))?_c('td',[(item.n_unconfirmed > 0)?_c('router-link',{staticClass:"has-issue error--text card-status-link lighten-error-color",attrs:{"to":_vm.generateLink(
                    item.organization_id,
                    item.farm_id,
                    'unconfirmed'
                  )}},[_vm._v(_vm._s(item.n_unconfirmed))]):_c('div',{staticClass:"no-issue"},[_vm._v("0")])],1):_vm._e(),(_vm.checkColumn('aep_loss_farm_pct_open'))?_c('td',[(item.aep_loss_farm_pct_open !== null)?_c('div',{staticClass:"site-name"},[_vm._v(" "+_vm._s(_vm.roundAepPct(item.aep_loss_farm_pct_open))+" ")]):_vm._e()]):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }