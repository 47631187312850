<template>
  <div>
    <div class="d-flex flex-wrap align-center justify-space-between">
      <div class="d-flex flex-wrap align-center mr-3">
        <div class="mr-3 mb-3 search-filter-wrapper">
          <v-text-field
            solo
            dense
            label="Search..."
            append-icon="mdi-magnify"
            hide-details
            class="no-border"
            data-ga="org_table_search"
            :value="searchText"
            @input="updateSearch"
          ></v-text-field>
        </div>
      </div>
      <div class="mb-3 column-filter-wrapper">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Column filters</span>
        </v-tooltip>
        <CustomMenuFilter
          :menuOpen="colFilterMenuOpen"
          menuActivator="#cog-icon"
          :menuItems="columnFilterMenuItems"
          :showAll="showAllCols"
          offset="y"
          filterType="column"
          table="org_table"
          @changeFilters="toggleColSelection"
          @clearSelectedItems="clearSelectedColItems"
          @setSearchText="setColumnFilterSearchText"
          @clearFilterSearch="clearColumnFilterSearch"
        />
      </div>
    </div>
    <div>
      <DataTable
        className="no-background org-table"
        itemKey="org_id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="searchText"
        :showSelect="false"
        :headers="mutableTableHeaders"
        :items="data"
        :page="page"
        :itemsPerPage="itemsPerPage"
        :loading="loading"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td v-if="checkColumn('name')">
                <span>
                  <router-link
                    class="orgname-link"
                    :to="{
                      name: 'OrgDashboard',
                      params: {
                        orgId: item.organization_id,
                      },
                    }"
                    >{{ item.name }}</router-link
                  >
                </span>
              </td>
              <td
                v-if="checkColumn('site_names')"
                class="d-flex align-center flex-wrap"
              >
                <div v-for="(site, index) in item.site_names" :key="site.id">
                  <div class="org-sites" style="white-space: nowrap">
                    {{ site
                    }}<span v-if="index + 1 < item.site_names.length"
                      >,&nbsp;</span
                    >
                  </div>
                </div>
              </td>
              <td v-if="checkColumn('n_open')">
                <router-link
                  v-if="item.n_open > 0"
                  class="has-issue error--text card-status-link lighten-error-color"
                  :to="generateLink(item.organization_id, 'open')"
                  >{{ item.n_open }}</router-link
                >
                <span v-else class="no-issue">0</span>
              </td>
              <td v-if="checkColumn('n_require_external_input')">
                <router-link
                  v-if="item.n_require_external_input > 0"
                  class="has-issue error--text card-status-link lighten-error-color"
                  :to="generateLink(item.organization_id, 'open')"
                  >{{ item.n_require_external_input }}</router-link
                >
                <span v-else class="no-issue">0</span>
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";
import CustomMenuFilter from "./CustomMenuFilter";
import { popupDelay } from "@/helpers/variables";

export default {
  name: "OrgTable",
  components: {
    DataTable,
    CustomMenuFilter,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
      required: false,
    },
    page: {
      type: Number,
      default: 1,
      required: false,
    },
    sortBy: {
      type: String,
      default: "name",
      required: false,
    },
    sortDesc: {
      type: Boolean,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      searchText: "",
      colFilterMenuOpen: false,
      searchColumnMenuText: "",
      popupLoading: false,
      showAllCols: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      popupDelay,
    };
  },
  computed: {
    tableHeaders() {
      let headerArr = [];
      if (this.headers.length > 0) {
        let count = 1;
        for (const header of this.headers) {
          let headerObj = {};
          headerObj.id = count;
          headerObj.text = header.text;
          headerObj.value = header.value;
          headerObj.sortable = header.sortable;
          headerObj.show = true;
          headerArr.push(headerObj);
          count++;
        }
      }

      return headerArr;
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  methods: {
    updateSearch(e) {
      const url = new URL(window.location.href);
      this.searchText = e;
      url.searchParams.set("search_for", e);
      if (!this.searchText) {
        url.searchParams.delete("search_for");
        url.searchParams.set("page", 1);
      }
      history.replaceState(null, null, url);
    },
    setSearchText() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get("search_for");
      this.updateSearch(search);
    },
    goToIssuesTable() {
      this.$emit("goToIssuesTable");
    },
    // goToOrgIssues(orgId, type) {

    // },
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    generateLink(orgId, type) {
      let includedStatuses = [];
      let link = {
        name: "OrgDashboard",
        params: { orgId: orgId },
        hash: "#issues",
      };
      if (type === "open") {
        includedStatuses = ["new", "in_progress"];
      } else {
        includedStatuses = [type];
      }
      link.query = {
        page: 1,
        items_per_page: 10,
        sort_by: "aep_loss_pct",
        sort_desc: 1,
        include_status: includedStatuses,
      };

      return link;
    },
  },
  mounted() {
    this.$emit("getOrgTableData");
    this.setSearchText();
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
};
</script>
<style lang="scss">
.org-table th {
  white-space: normal;
}
</style>
<style lang="scss" scoped>
.orgname-link {
  color: var(--v-black1-base);
  text-decoration: none;
}
.orgname-link:hover {
  color: var(--v-primary-base);
  text-decoration: underline;
}
</style>
