<template>
  <div>
    <section class="mb-4 d-flex align-center">
      <Header :title="`Welcome ${firstName.trim()}!`">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              data-ga="icon_home_page_force_refresh"
              v-bind="attrs"
              v-on="on"
              @click="forceRefresh()"
              class="mr-2 icon-hover"
              >mdi-refresh</v-icon
            >
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </Header>
    </section>
    <SummaryWidget
      :summary="summaryData"
      :loading="loading.getSummary"
      :popupLoading="popupLoading"
      :isHomePage="true"
      @goToIssuesTable="goToIssuesTab"
      @goToChecksTab="goToChecksTab"
    />
    <section class="mt-7 mb-3">
      <div class="d-flex">
        <v-tabs v-model="tab" class="mb-4">
          <v-tab
            data-cy="home-v-tabs"
            data-ga="home_tab"
            v-for="(tab, index) in dashboardTabs"
            @click="handleTabClick(tab)"
            :key="index"
            class="no-background"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </div>
      <v-row>
        <v-col lg="9">
          <v-tabs-items v-model="tab" class="no-background">
            <v-tab-item
              v-for="tabItem in dashboardTabs"
              :key="tabItem.value"
              :transition="false"
            >
              <div class="dynamic-tabs-container">
                <template v-if="tabItem.value === 'checks'">
                  <KeepAlive>
                    <ChecksTable
                      v-if="dashboardTabs[tab].value === 'checks'"
                      :data="checks"
                      :issues="allIssues"
                      :loading="loading.getChecks"
                      :userHasInternalAccess="userData.is_internal"
                      :itemsPerPage="5"
                      :pageOptions="pageOptions"
                      sortBy="aep_loss_farm_pct_open"
                      @goToIssuesTable="goToIssuesTab"
                      @getChecksTableData="callForChecks"
                    />
                  </KeepAlive>
                </template>
                <template v-if="tabItem.value === 'orgs'">
                  <OrgTable
                    v-if="dashboardTabs[tab].value === 'orgs'"
                    :headers="orgHeaders"
                    :data="issuesGroupedByOrg"
                    :loading="loading.getIssuesGroupedByOrg"
                    :itemsPerPage="5"
                    sort-by="n_require_external_input"
                    :sort-desc="true"
                    @goToIssuesTable="goToIssuesTab"
                    @getOrgTableData="callForOrgs"
                  />
                </template>
                <template v-if="tabItem.value === 'sites'">
                  <SiteTable
                    v-if="dashboardTabs[tab].value === 'sites'"
                    :headers="headers"
                    :data="siteTableData"
                    :loading="loading.getIssuesGroupedBySite"
                    :issues="allIssues"
                    :itemsPerPage="pageOptions.items_per_page"
                    :page="pageOptions.page"
                    sort-by="aep_loss_farm_pct_open"
                    :sort-desc="pageOptions.sort_desc ? true : false"
                    @goToIssuesTable="goToIssuesTab"
                  />
                </template>
                <template v-if="tabItem.value === 'oems'">
                  <OemTable
                    v-if="dashboardTabs[tab].value === 'oems'"
                    :data="issuesGroupedByOEM"
                    :loading="loading.getIssuesGroupedByOEM"
                    :itemsPerPage="5"
                    sortBy="aep_loss_farm_pct"
                    @getOemsTableData="callForOems"
                  />
                </template>
                <!-- allIssuesData -->
                <template v-if="tabItem.value === 'issues'">
                  <IssueTable
                    ref="issuesTable"
                    class="all-issue-table"
                    v-if="dashboardTabs[tab].value === 'issues'"
                    :tab="dashboardTabs[tab].value"
                    :data="paginatedIssuesForIssuesTable"
                    :isHomePage="true"
                    :loading="loading.getPaginatedIssuesForIssuesTable"
                    :totalItems="issuesTotal"
                    :itemsPerPage="5"
                    :footerProps="{
                      'items-per-page-options': [5, 10, 15, 25, 50],
                    }"
                    @paginationUpdated="getNewItemSetForIssuesTable"
                  />
                </template>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <SwitchButton
            label="Show map"
            color="primary"
            @changeVal="changeShowMap"
          />
          <MapView
            v-if="showMap"
            :mapData="mapConfig"
            :isMapLoading="
              issuesGroupedBySite.length === 0 || loading.getIssuesGroupedBySite
            "
          />
        </v-col>
        <v-col sm="12" md="12" lg="3">
          <v-card outlined style="border-radius: 8px">
            <v-card-title>Not sure where to start?</v-card-title>
            <v-card-text
              ><a
                :href="portalTrainingBaseUrl"
                data-ga="home_link_knowledge_base"
                target="_blank"
                >Check out our training documentation</a
              ></v-card-text
            >
            <v-card-text style="padding-top: 0"
              ><router-link to="/glossary" data-ga="home_link_glossary"
                >Glossary</router-link
              ></v-card-text
            >
          </v-card>
          <v-card
            outlined
            style="border-radius: 8px"
            class="mt-6 notification-panel"
          >
            <v-card-title>Recent activity</v-card-title>
            <div>
              <div
                class="d-flex justify-center align-center notification-panel__spinner"
                v-if="
                  mostRecentNotifications.length === 0 || recentActivityLoading
                "
              >
                <v-progress-circular
                  :size="40"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <v-card-text v-else data-cy="notification-home"
                ><Notifications
                  :notifications="mostRecentNotifications"
                  :allowArchive="false"
              /></v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import L from "leaflet";
import SummaryWidget from "@/components/SummaryWidget";
import OrgTable from "@/components/OrgTable";
import SiteTable from "@/components/SiteTable";
import OemTable from "@/components/OemTable";
import IssueTable from "@/components/IssueTable";
import ChecksTable from "@/components/ChecksTable";
import MapView from "@/components/MapView";
import SwitchButton from "@/components/SwitchButton";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  portalTrainingBaseUrl,
} from "@/helpers/variables";
import {
  roundToString,
  roundAepPct,
  getDashboardTabs,
  setUrlParams,
} from "@/helpers/functions";
import Header from "@/components/Header";
import Notifications from "@/components/Notifications";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "HomePage",
  components: {
    SummaryWidget,
    OrgTable,
    SiteTable,
    OemTable,
    IssueTable,
    ChecksTable,
    MapView,
    SwitchButton,
    Header,
    Notifications,
  },
  data() {
    return {
      showMap: true,
      headers: [
        { text: "Name", sortable: true, value: "site_name" },
        { text: "Subscription", sortable: false, value: "subscription" },
        { text: "Turbine model(s)", sortable: true, value: "turbine_models" },
        { text: "Open issues", sortable: true, value: "n_open" },
        {
          text: "Issues that need attention",
          sortable: true,
          value: "n_req_attn",
        },
        {
          text: "Unconfirmed issues",
          sortable: true,
          value: "n_unconfirmed",
        },
        {
          text: "AEP potential (%)",
          sortable: true,
          value: "aep_loss_farm_pct_open",
        },
      ],
      orgHeaders: [
        { text: "Name", sortable: true, value: "name" },
        { text: "Sites", sortable: true, value: "site_names" },
        { text: "Open issues", sortable: true, value: "n_open" },
        {
          text: "Issues that need attention",
          sortable: true,
          value: "n_require_external_input",
        },
      ],
      tab: 0,
      portalTrainingBaseUrl,
      setUrlParams,
      pageOptions: {
        items_per_page: 5,
        page: 1,
        sort_by: "n_req_attn",
        sort_desc: true,
      },
      popupLoading: false,
      leftmostTab: "",
      dashboardTabs: [],
      paginatedIssuesForIssuesTableLoading: false,
      newItemSetRequest: {},
      recentActivityLoading: false,
      mapData: {},
    };
  },
  computed: {
    ...mapState({
      firstName: (state) => state.user.userData.name_first,
      userData: (state) => state.user.userData,
      notifications: (state) => state.notifications.dropdownNotifications,
      notificationsLoading: (state) =>
        state.notifications.loading.getUserNotifications,
      allIssues: (state) => state.issues.allIssues,
      paginatedIssuesForIssuesTable: (state) =>
        state.home.paginatedIssuesForIssuesTable,
      issuesTotal: (state) => state.home.issuesTotal,
      summary: (state) => state.home.summary,
      checks: (state) => state.home.checks,
      issuesGroupedByOrg: (state) => state.home.issuesGroupedByOrg,
      issuesGroupedBySite: (state) => state.home.issuesGroupedBySite,
      issuesGroupedByOEM: (state) => state.home.issuesGroupedByOEM,
      clickCount: (state) => state.app.clickCount,
      loading: (state) => state.home.loading,
    }),
    mostRecentNotifications() {
      // eslint-disable-next-line no-undef
      const notifications = structuredClone(this.notifications);
      return notifications?.splice(0, 5);
    },
    siteTableData() {
      if (this.issuesGroupedBySite?.length > 0) {
        let subscription = "";
        return this.issuesGroupedBySite.map((item) => {
          if (item.sow_def_name) {
            if (
              item.sow_def_name === "Find, Fix, Measure" ||
              item.sow_def_name === "Find-Only"
            ) {
              subscription = "Pulse";
            } else {
              subscription = item.sow_def_name;
            }
          } else {
            subscription = item.subscription;
          }

          return {
            ...item,
            selected: false,
            site_id: item.farm_id ? item.farm_id : item.id,
            site_name: item.farm_name ? item.farm_name : item.site_name,
            n_open: item.n_open ? item.n_open : 0,
            n_req_attn: item.n_require_external_input
              ? item.n_require_external_input
              : 0,
            subscription: subscription,
            color: item.n_require_external_input > 0 ? "red" : "blue",
            position: L.latLng(item.latitude_deg, item.longitude_deg),
            mapInfoTitle: item.farm_name ? item.farm_name : item.site_name,
            mapInfoDesc: `Open issues: ${item.n_open ? item.n_open : 0}`,
            mapInfoRoute: `/sites/${item.farm_id ? item.farm_id : item.id}`,
          };
        });
      } else {
        return [];
      }
    },
    summaryData() {
      if (this.summary && "n_orgs" in this.summary) {
        const summaryUnconfirmed = this.setSummaryUnconfirmed();
        return {
          capacity: {
            title: "Total capacity",
            mw: this.summary.total_capacity_mw
              ? roundToString(this.summary.total_capacity_mw, -1)
              : 0,
            nOrgs: this.summary.n_orgs ? this.summary.n_orgs : 0,
            nSites: this.summary.n_sites ? this.summary.n_sites : 0,
            nTurbines: this.summary.n_turbines ? this.summary.n_turbines : 0,
            nOems: this.summary.n_manufacturers
              ? this.summary.n_manufacturers
              : 0,
          },
          issue: {
            title: "Issues",
            issueDefIdCount:
              this.summary.turbine_issue_def_ids_require_external_input?.length,
            needAttn: this.summary.n_require_external_input
              ? this.summary.n_require_external_input
              : 0,
            open: this.summary.n_open ? this.summary.n_open : 0,
            fixed: this.summary.n_fixed_or_resolved
              ? this.summary.n_fixed_or_resolved
              : 0,
            unconfirmed: summaryUnconfirmed,
          },
          totalGains: {
            title: "AEP gain from fixed issues",
            mwh: this.summary.aep_gain_mwhpyr_fixed_or_resolved
              ? roundToString(
                  this.summary.aep_gain_mwhpyr_fixed_or_resolved,
                  -1,
                )
              : 0,
            usd: this.summary.aep_gain_upper_usdpyr_fixed_or_resolved
              ? roundToString(
                  this.summary.aep_gain_upper_usdpyr_fixed_or_resolved,
                  -1,
                )
              : 0,
            percent: this.summary.aep_gain_farm_pct_fixed_or_resolved
              ? roundAepPct(this.summary.aep_gain_farm_pct_fixed_or_resolved)
              : 0,
          },
          aepPotential: {
            title: "AEP potential for open issues",
            mwh: this.summary.aep_loss_mwhpyr_open
              ? roundToString(this.summary.aep_loss_mwhpyr_open, -1)
              : 0,
            usd: this.summary.aep_loss_upper_usdpyr_open
              ? roundToString(this.summary.aep_loss_upper_usdpyr_open, -1)
              : 0,
            percent: this.summary.aep_loss_farm_pct_open
              ? roundAepPct(this.summary.aep_loss_farm_pct_open)
              : 0,
          },
        };
      } else {
        return {};
      }
    },
    mapConfig() {
      return {
        zoom: 10,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
        markers: this.siteTableData,
      };
    },
    issuesTableData() {
      if (this.paginatedIssuesForIssuesTable.length > 0) {
        return this.paginatedIssuesForIssuesTable;
      } else {
        return [];
      }
    },
    isInternal() {
      return this.userData?.is_internal;
    },
  },
  methods: {
    ...mapActions({
      getIssues: "issues/getIssues",
      getPaginatedIssuesForIssuesTable: "home/getPaginatedIssuesForIssuesTable",
      getChecks: "home/getChecks",
      getIssuesGroupedByOrg: "home/getIssuesGroupedByOrg",
      getIssuesGroupedBySite: "home/getIssuesGroupedBySite",
      getIssuesGroupedByOEM: "home/getIssuesGroupedByOEM",
      getSummary: "home/getSummary",
      getUserNotifications: "notifications/getUserNotifications",
      incrementClickCount: "app/incrementClickCount",
    }),
    async callInitialAPIs(refresh) {
      // Call for site table data to populate map
      if (
        (this.issuesGroupedBySite?.length === 0 && !refresh) ||
        (this.issuesGroupedBySite?.length > 0 && refresh)
      ) {
        this.getIssuesGroupedBySite();
      }
      // Data for dashboard top widget cards
      if (this.summary && "n_unconfirmed" in this.summary && !refresh) {
        return;
      } else {
        this.getSummary();
      }
    },
    setHeaders() {
      if (!this.isInternal) {
        this.headers.splice(5, 1);
      }
    },
    handleTabClick(tab) {
      const url = new URL(window.location.href);
      url.search = "";
      url.hash = tab.value;

      if ((tab.value === "checks" || tab === 0) && url.hash !== "checks") {
        url.hash = "checks";
        url.searchParams.set("items_per_page", 5);
        url.searchParams.set("page", 1);
        url.searchParams.set("sort_by", "aep_loss_farm_pct_open");
        url.searchParams.set("sort_desc", 1);
        this.callForChecks("tabClicked");
      }

      history.replaceState(null, null, url);
    },
    goToTab() {
      const hashName = decodeURI(window.location.hash).split("#")[1];
      const tabIndex = this.dashboardTabs.findIndex(
        (x) => x.value === hashName,
      );
      if (tabIndex > -1) {
        this.tab = tabIndex;
      }
      // If tab is for checks
      if (this.tab === 0 && this.checks?.length === 0) {
        this.callForChecks("goToTab");
      }
    },
    setSummaryUnconfirmed() {
      if (this.isInternal) {
        return this.summary.n_unconfirmed;
      }
    },
    changeShowMap(value) {
      this.showMap = value;
      const eventLabel = `home_map_toggle_${value}`;
      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    goToChecksTab() {
      for (const tab of this.dashboardTabs) {
        if (tab.value === "checks") {
          this.tab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
      this.handleTabClick(this.tab);
    },
    callForChecks(type) {
      const url = new URL(window.location.href);
      if (this.loading.getChecks) {
        return;
      }
      if (this.checks?.length === 0) {
        if (
          url.hash === "#checks" ||
          type === "tabClicked" ||
          type === "goToTab"
        ) {
          this.getChecks();
        }
      }
    },
    callForOrgs() {
      if (!this.issuesGroupedByOrg || this.issuesGroupedByOrg?.length === 0) {
        this.getIssuesGroupedByOrg();
      }
    },
    callForOems() {
      if (this.issuesGroupedByOEM?.length === 0) {
        this.getIssuesGroupedByOEM();
      }
    },
    async getNewItemSetForIssuesTable(payload) {
      this.newItemSetRequest = payload;
      await this.getPaginatedIssuesForIssuesTable(payload);
    },
    goToIssuesTab(type, source, name) {
      const url = new URL(window.location.href);
      let tempUrl = null;
      let newUrl = null;
      url.search = "";
      // Set issue status filters
      if (type && source) {
        let params = {
          include_statuses: [],
          page: 1,
          items_per_page: 5,
          sort_by: "aep_loss_pct",
          sort_desc: 1,
        };
        if (type === "open") {
          params.include_statuses = ["new", "in_progress"];
        } else if (type === "fixed") {
          params.include_statuses = ["fixed"];
        } else if (type === "unconfirmed") {
          params.include_statuses = ["unconfirmed", "needs_review"];
        }
        if (name) {
          params.search_for = name;
        }
        // Call method inside issues table to set filters inside issues table
        if (url.hash === "#issues" && source === "summary") {
          this.$refs["issuesTable"][0].changeStatusFilters(
            params.include_statuses,
          );
        }
        tempUrl = this.setUrlParams(url, params, "include_status");
      }

      url.hash = "issues";
      if (tempUrl) {
        newUrl = tempUrl;
      } else {
        newUrl = url;
      }
      history.replaceState(null, null, newUrl);

      for (const tab of this.dashboardTabs) {
        if (tab.value === "issues") {
          this.tab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
    },
    async forceRefresh() {
      this.callInitialAPIs("refresh");
      if (this.checks?.length > 0) {
        this.getChecks();
      }
      if (this.issuesGroupedByOrg?.length > 0) {
        this.getIssuesGroupedByOrg();
      }
      if (this.issuesGroupedByOEM?.length > 0) {
        this.getIssuesGroupedByOEM();
      }
      if (
        this.paginatedIssuesForIssuesTable?.length > 0 &&
        this.newItemSetRequest &&
        "page" in this.newItemSetRequest
      ) {
        this.getPaginatedIssuesForIssuesTable(this.newItemSetRequest);
      }
      if (this.notifications?.length > 0) {
        this.getUserNotifications({
          limit: 20,
          offset: 0,
          get_total_count: false,
          get_unread_unarchived_count: true,
          container: "dropdown",
        });
      }
    },
  },
  beforeMount() {
    this.leftmostTab = "checks";
    this.dashboardTabs = getDashboardTabs("home", this.leftmostTab);
    this.callInitialAPIs();
    this.setHeaders();
  },
  mounted() {
    const url = new URL(window.location.href);
    if (!url.hash) {
      url.hash = this.leftmostTab;
    }
    this.goToTab();

    history.replaceState(null, null, url);

    window.addEventListener(
      "hashchange",
      () => {
        this.goToTab();
      },
      false,
    );
  },
  watch: {
    headers: {
      immediate: true,
      handler(data) {
        this.columns = data ? data : [];
        this.displayColumns = [...this.columns];
        this.issueStatus = [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-panel {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.map-switch {
  position: absolute;
  top: 40%;
  left: 0;
  z-index: 450;
}
</style>
