<template>
  <div>
    <div v-for="(notification, index) in notificationsArr" :key="index">
      <div>
        <div class="d-flex mb-2 justify-space-between">
          <div class="icon-container d-flex">
            <v-icon
              :color="setNotificationStyle(notification, 'color')"
              class="mr-2"
            >
              {{ setNotificationStyle(notification, "icon") }}
            </v-icon>
          </div>
          <div class="d-flex align-center mt-1 pb-1">
            <div
              :data-cy="`notification-ts_${index}`"
              class="grey--text text-caption mr-1 notification-timestamp"
            >
              {{ notification.created_ts }}
            </div>
          </div>
        </div>
        <div
          class="d-flex message-wrapper"
          @mouseenter="showArchiveIcon(notification)"
          @mouseleave="hideArchiveIcon()"
        >
          <a
            class="pl-2 pr-2 pt-1 pb-1"
            :href="notification.url"
            @click.prevent="goToSelection(notification)"
            :data-cy="`notification-link_${notification.entity_type}_${index}`"
            >{{ notification.message }}</a
          >
          <div
            class="pointer mr-3 archive-icon"
            v-if="notificationId === notification.id && allowArchive"
            @click="markAsArchived(notification)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-archive-arrow-up-outline</v-icon
                >
              </template>
              <span>Archive notification</span>
            </v-tooltip>
          </div>
        </div>
        <v-divider class="mb-3"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { notificationStyles } from "@/helpers/variables";
import { debounce } from "lodash";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "Notifications",
  data() {
    return {
      notificationStyles,
      archiveIcon: "mdi-archive-plus-outline",
      archiveTooltip: "Archive notification",
      archiveIconVisible: false,
      notificationId: null,
      showNotifications: false,
      timeout: null,
      debounceMarkAsRead: null,
      notificationsArr: [],
    };
  },
  props: {
    notifications: {
      type: Array,
      required: true,
      default: () => [],
    },
    allowArchive: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState({
      readSwitch: (state) => state.notifications.readSwitchValue,
      archiveSwitch: (state) => state.notifications.archiveSwitchValue,
      commentsSwitch: (state) => state.notifications.commentsSwitchValue,
      issueStatusesSwitch: (state) =>
        state.notifications.issueStatusesSwitchValue,
      unreadNotificationsCount: (state) =>
        state.notifications.unreadNotificationsCount,
      notificationPagination: (state) =>
        state.notifications.notificationPagination,
    }),
    filteredResults() {
      if (this.filter !== "") {
        return this.notifications.filter(
          (x) =>
            x.actor_user_full_name
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            x.message.toLowerCase().includes(this.filter.toLowerCase()) ||
            x.ts.includes(this.filter),
        );
      }

      return this.notifications;
    },
    pagination() {
      if (this.notificationPagination?.limit) {
        return this.notificationPagination;
      } else {
        return {
          limit: 10,
          page: 1,
        };
      }
    },
  },
  methods: {
    ...mapActions({
      getUserNotifications: "notifications/getUserNotifications",
      setUploadNotification: "notifications/saveUploadNotification",
      markNotificationReadArchived: "notifications/putNotificationReadArchived",
      updateIsNotificationNew: "notifications/updateIsNotificationNew",
      updateIssueTurbineId: "issueDetail/updateIssueTurbineId",
    }),
    goToIssue(notification) {
      if (this.$route.hash) {
        this.$router.push({
          hash: "",
        });
      }
      let issueIdParam = +this.$route.params.issueId;
      this.updateIsNotificationNew(true);
      this.updateIssueTurbineId(notification.turbine_id);
      let routerParams = {
        name: "IssueDetail",
        params: {
          orgId: notification.org_id,
          siteId: notification.site_id,
          turbineId: notification.turbine_id,
          issueId: notification.turbine_issue_id,
        },
        query: this.$route.query,
      };
      if (issueIdParam !== notification.turbine_issue_id) {
        if (notification.entity_type === "turbine_issue_comment") {
          routerParams.hash = `#comment-${notification.entity_id}`;
        }
        this.$router.push(routerParams);
      } else {
        if (notification.entity_type === "turbine_issue_comment") {
          this.$router.push({
            hash: `#comment-${notification.entity_id}`,
          });
        }
      }
    },
    goToDocuments(notification) {
      const siteId = this.$route.params?.siteId;
      const name = this.$route.name;
      const docUpload = {
        subTab: "documents",
        fileId: notification.entity_id,
        siteId: notification.site_id,
      };
      // Use vuex store to save entity info
      this.setUploadNotification(docUpload);
      if (name !== "SiteDashboard" || +siteId !== notification.site_id) {
        this.$router.push({
          name: "SiteDashboard",
          params: {
            orgId: notification.org_id,
            siteId: notification.site_id,
          },
        });
      }
    },
    goToSiteDashboard(notification) {
      const url = new URL(window.location.href);
      const siteId = this.$route.params?.siteId;
      const name = this.$route.name;
      const alertObj = {
        subTab: "data",
        siteId: siteId,
      };
      url.search = "";
      url.hash = "";
      history.replaceState(null, null, url);
      // Use vuex store to save entity info
      this.setUploadNotification(alertObj);
      if (name !== "SiteDashboard" || +siteId !== notification.site_id) {
        this.$router.push({
          name: "SiteDashboard",
          params: {
            orgId: notification.org_id,
            siteId: notification.site_id,
          },
        });
      }
    },
    goToSelection(notification) {
      if (notification) {
        this.updateIssueTurbineId(notification.turbine_id);
        switch (notification.entity_type) {
          case "farm_document":
            this.goToDocuments(notification);
            break;
          case "turbine_issue_status":
            this.goToIssue(notification);
            break;
          case "turbine_issue_comment":
            this.goToIssue(notification);
            break;
          case "alert":
            this.goToSiteDashboard(notification);
            break;
        }
        this.$emit("toggleShowNotifications", false);
      }
    },
    setNotificationStyle(notification, property) {
      let notificationStyle = {};
      let notificationType = notification.entity_type;
      if (notificationType === "alert") {
        // Need to figure out the state of the alert
        notificationType =
          notificationType +
          "-" +
          notification.message.toLowerCase().split(":")[0];
      } else if (notificationType === "turbine_issue_status") {
        if (notification.message.toLowerCase().includes("fixed")) {
          notificationType += "-fixed";
        }
      }

      notificationStyle = this.notificationStyles.find(
        (item) => item.title === notificationType,
      );

      if (notificationStyle && property === "color") {
        return notificationStyle.color;
      }
      if (notificationStyle && property === "icon") {
        return notificationStyle.icon;
      }
    },
    // Using debounce
    async markAsRead(notification) {
      let notificationObj = {};
      if (!notification.read) {
        notificationObj = {
          id: notification.id,
          read: true,
          archived: notification.archived,
        };
      }
      await this.updateNotification(notificationObj);
    },
    async markAsArchived(notification) {
      const notificationObj = {
        id: notification.id,
        archived: true,
        read: notification.read,
      };
      await this.updateNotification(notificationObj);
    },
    async updateNotification(notificationObj) {
      await this.markNotificationReadArchived(notificationObj);
      this.refreshUserNotifications();
      if (this.$route.path === "/notifications") {
        this.getUserNotifications({
          limit: this.pagination.limit,
          offset: 0,
          read: this.readSwitch,
          archived: this.archiveSwitch,
          comments: this.commentsSwitch,
          issue_statuses: this.issueStatusesSwitch,
        });
      }
    },
    async refreshUserNotifications() {
      await this.getUserNotifications({
        limit: 20,
        offset: 0,
        container: "dropdown",
      });
    },
    showArchiveIcon(notification) {
      this.archiveIconVisible = true;
      this.notificationId = notification.id;
      this.debounceMarkAsRead = debounce(() => {
        if (!notification.read) {
          this.markAsRead(notification);
        }
      }, 500);
      this.debounceMarkAsRead();
    },
    hideArchiveIcon() {
      // Cancel the markAsRead method
      this.debounceMarkAsRead.cancel();
      this.archiveIconVisible = false;
      this.notificationId = null;
    },
    setNotificationUrl(notifications) {
      const url = new URL(window.location.href);
      if (notifications.length > 0) {
        let notifArr = [...notifications];
        for (const item of notifArr) {
          switch (item.entity_type) {
            case "farm_document":
              item.url = url.origin + `/sites/${item.site_id}`;
              break;
            case "turbine_issue_status":
              item.url = url.origin + `/turbine-issue/${item.turbine_issue_id}`;
              break;
            case "turbine_issue_comment":
              item.url = url.origin + `/turbine-issue/${item.turbine_issue_id}`;
              break;
            case "alert":
              item.url = url.origin + `/sites/${item.site_id}`;
              break;
          }
        }
        this.notificationsArr = notifArr;
      }
    },
  },
  watch: {
    notifications: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.setNotificationUrl(data);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables";
.pointer:hover {
  cursor: pointer;
}

.unread-indicator {
  position: absolute;
  top: 5%;
  left: 45%;
  width: 14px;
  line-height: 14px;
  border-radius: 50%;
  text-align: center;
  font-size: 7px;
  padding-left: 0.5px;
  color: white;
  background-color: $accentBlue;
  z-index: 10;
}

.message-wrapper {
  position: relative;
  line-height: 1.5em;
  // With coreui enabled, styling is added to body, interacting with following rules
  // font-size: 0.875em; TODO: Enable once coreui is removed
  cursor: pointer;
}

.message-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.pic-container {
  overflow: hidden;
  height: 400px; /* Would be neater if auto, but scrolling will not work */
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}

.notification-timestamp {
  padding-bottom: 1px;
}

.menu-container {
  position: relative;
}

main .icon-container {
  position: relative;
}
.v-tooltip {
  z-index: 10000;
}
</style>
<style scoped lang="scss">
.message-wrapper {
  a {
    color: var(--v-text-base);
    text-decoration: none;
  }
}
</style>
