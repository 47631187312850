var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('div',[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"oem_table_search","value":_vm.searchText},on:{"input":_vm.updateSearch}})],1)]),_c('div',{staticClass:"mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"oem_table"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('div',[_c('DataTable',{attrs:{"className":"no-background clickable-table issues-table","itemKey":"id","search":_vm.searchText,"showSelect":false,"headers":_vm.mutableTableHeaders,"items":_vm.tableData,"itemsPerPage":5,"loading":_vm.loading,"sortBy":_vm.sortBy,"sort-desc":true,"table":"oem"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,class:'no-pointer',on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('manufacturer_name'))?_c('td',[_vm._v(" "+_vm._s(item.manufacturer_name)+" ")]):_vm._e(),(_vm.checkColumn('n_open'))?_c('td',[_c('span',{class:[
                  item.n_open > 0 ? 'has-issue error--text' : 'no-issue' ]},[_vm._v(_vm._s(item.n_open))])]):_vm._e(),(_vm.checkColumn('n_require_external_input'))?_c('td',[_c('span',{class:[
                  item.n_require_external_input > 0
                    ? 'has-issue error--text'
                    : 'no-issue' ]},[_vm._v(_vm._s(item.n_require_external_input))])]):_vm._e(),(_vm.checkColumn('loss_upper_usd'))?_c('td',[(item.loss_upper_usd)?_c('div',{staticClass:"site-name"},[_vm._v(" $"+_vm._s(_vm.roundToString(item.loss_upper_usd, 0))+" ")]):_vm._e()]):_vm._e(),(_vm.checkColumn('aep_loss_farm_pct'))?_c('td',[(
                  item.aep_loss_farm_pct !== null &&
                  _vm.roundToString(item.aep_loss_farm_pct, 1) != '0.0'
                )?_c('div',{staticClass:"site-name"},[_vm._v(" "+_vm._s(_vm.roundToString(item.aep_loss_farm_pct, 1))+" ")]):_vm._e()]):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }