<template>
  <div>
    <div class="d-flex flex-wrap align-center justify-space-between">
      <div>
        <div class="mr-3 mb-3 search-filter-wrapper">
          <v-text-field
            solo
            dense
            label="Search..."
            append-icon="mdi-magnify"
            hide-details
            class="no-border"
            data-ga="oem_table_search"
            :value="searchText"
            @input="updateSearch"
          ></v-text-field>
        </div>
      </div>

      <div class="mb-3 column-filter-wrapper">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Column filters</span>
        </v-tooltip>
        <CustomMenuFilter
          :menuOpen="colFilterMenuOpen"
          menuActivator="#cog-icon"
          :menuItems="columnFilterMenuItems"
          :showAll="showAllCols"
          offset="y"
          filterType="column"
          table="oem_table"
          @changeFilters="toggleColSelection"
          @clearSelectedItems="clearSelectedColItems"
          @setSearchText="setColumnFilterSearchText"
          @clearFilterSearch="clearColumnFilterSearch"
        />
      </div>
    </div>
    <div>
      <DataTable
        className="no-background clickable-table issues-table"
        itemKey="id"
        :search="searchText"
        :showSelect="false"
        :headers="mutableTableHeaders"
        :items="tableData"
        :itemsPerPage="5"
        :loading="loading"
        :sortBy="sortBy"
        :sort-desc="true"
        table="oem"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selectItem(item)"
              @mouseleave="unselectItem(item)"
              :class="'no-pointer'"
            >
              <td v-if="checkColumn('manufacturer_name')">
                {{ item.manufacturer_name }}
              </td>
              <td v-if="checkColumn('n_open')">
                <span
                  :class="[
                    item.n_open > 0 ? 'has-issue error--text' : 'no-issue',
                  ]"
                  >{{ item.n_open }}</span
                >
              </td>
              <td v-if="checkColumn('n_require_external_input')">
                <span
                  :class="[
                    item.n_require_external_input > 0
                      ? 'has-issue error--text'
                      : 'no-issue',
                  ]"
                  >{{ item.n_require_external_input }}</span
                >
              </td>
              <td v-if="checkColumn('loss_upper_usd')">
                <div v-if="item.loss_upper_usd" class="site-name">
                  ${{ roundToString(item.loss_upper_usd, 0) }}
                </div>
              </td>
              <td v-if="checkColumn('aep_loss_farm_pct')">
                <div
                  v-if="
                    item.aep_loss_farm_pct !== null &&
                    roundToString(item.aep_loss_farm_pct, 1) != '0.0'
                  "
                  class="site-name"
                >
                  {{ roundToString(item.aep_loss_farm_pct, 1) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import DateFilter from "./DateFilter";
import { statusColors, popupDelay } from "@/helpers/variables";
import { roundToString } from "@/helpers/functions";
import DataTable from "@/components/DataTable";
import CustomMenuFilter from "./CustomMenuFilter";

export default {
  name: "OemTable",
  props: {
    selectItem: {
      type: Function,
      default: () => {},
    },
    unselectItem: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
      required: false,
    },
    sortBy: {
      type: String,
      required: false,
      default: "aep_loss_farm_pct",
    },
  },
  components: {
    "date-filter": DateFilter,
    DataTable,
    CustomMenuFilter,
  },
  data() {
    return {
      searchText: "",
      statusColors,
      roundToString,
      popupDelay,
      popupLoading: false,
      colFilterMenuOpen: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAllCols: false,
    };
  },
  computed: {
    ...mapState({
      issueStatusDefinitions: (state) => state.issues.issueStatusDefinitions,
    }),
    tableHeaders() {
      let headers = [
        { id: 1, text: "OEM", value: "manufacturer_name", show: true },
        { id: 2, text: "Open issues", value: "n_open", show: true },
        {
          id: 3,
          text: "Issues that need attention",
          value: "n_require_external_input",
          show: true,
        },
        {
          id: 4,
          text: "Cumulative loss (USD)",
          value: "loss_upper_usd",
          show: true,
        },
        {
          id: 5,
          text: "AEP potential (%)",
          value: "aep_loss_farm_pct",
          show: true,
        },
      ];
      return headers;
    },
    tableData() {
      if (this.data?.length > 0) {
        return this.data.map((r) => ({
          issues: r.issues,
          n_open: r.n_open,
          n_require_external_input: r.n_require_external_input,
          id: r.id,
          farm_name: r.farm_name,
          turbine_name: r.turbine_name,
          manufacturer_name: r.manufacturer_name,
          turbine_issue_def_name: r.name,
          turbine_issue_status: r.current_status_def_name_external,
          turbine_issue_def_status_id: r.current_status_def_id,
          date_found: r.start_ts
            ? dayjs(r.start_ts).format("YYYY-MM-DD")
            : "N/A",
          energy_loss: r.loss_mwh ? r.loss_mwh.toFixed(2) : 0,
          loss_upper_usd:
            r.loss_upper_usd !== null ? Math.round(r.loss_upper_usd) : null,
          aep_loss_pct:
            r.aep_loss_pct !== null ? r.aep_loss_pct.toFixed(1) : null,
          turbine_id: r.turbine_id ? r.turbine_id : null,
          description: r.description,
          turbine_issue_ids_open: r.turbine_issue_ids_open,
          turbine_issue_ids_require_external_input:
            r.turbine_issue_ids_require_external_input,
        }));
      } else {
        return [];
      }
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },

  methods: {
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    updateSearch(e) {
      const url = new URL(window.location.href);
      this.searchText = e;
      url.searchParams.set("search_for", e);
      if (!this.searchText) {
        url.searchParams.delete("search_for");
        url.searchParams.set("page", 1);
      }
      history.replaceState(null, null, url);
    },
    setSearchText() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get("search_for");
      this.updateSearch(search);
    },
  },
  mounted() {
    this.setSearchText();
    this.$emit("getOemsTableData");
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.status {
  border-radius: 4px;
  padding: 6px 8px;
  margin: 6px 0;
  display: inline-block;
  color: $black3;
}
.issue-column {
  max-width: 300px;
}
.info-icon.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-filter {
  max-width: 18rem;
  width: 18rem;
}

.issue-filter {
  max-width: 9rem;
}

.columns-filter {
  max-width: 8rem;
}
.no-pointer {
  cursor: default;
}
</style>

<style lang="scss">
.issues-table th {
  white-space: normal;
}
</style>
