<template>
  <div class="date-filter">
    <date-range-picker
      ref="picker"
      :locale-data="locale"
      :ranges="ranges"
      v-model="dateRange"
      @update="updateDateRange"
    >
      <div slot="input">
        <div class="d-flex justify-space-between align-center">
          <div class="v-label" v-if="dateRange.startDate && dateRange.endDate">
            {{ dateRange.startDate | date }} - {{ dateRange.endDate | date }}
          </div>
          <div class="v-label" v-else>{{ placeholder }}</div>

          <v-icon>mdi-menu-down</v-icon>
        </div>
      </div>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import dayjs from "dayjs";
/**
 * Can be used to select a date range by which to filter
 *
 * Currently, it is only used to display a placeholder name for a date column
 */
export default {
  name: "DateFilter",
  components: {
    DateRangePicker,
  },
  emits: ["changeDate"],
  props: {
    /**
     * The name passed in to be used for a date column name
     */
    placeholder: String,
  },
  data() {
    return {
      /**
       * The date range by which a date column can be sorted
       */
      dateRange: {
        startDate: null,
        endDate: null,
      },
      /**
       * Config for local timezone displays for dates
       */
      locale: {
        direction: "ltr", //direction of text
        format: "yyyy-mm-dd",
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      /**
       * Holds the ranges by which a date column can be sorted
       */
      ranges: {
        Today: [dayjs().toDate(), dayjs().toDate()],
        Yesterday: [
          dayjs().subtract(1, "days").toDate(),
          dayjs().subtract(1, "days").toDate(),
        ],
        "Last 7 Days": [dayjs().subtract(6, "days").toDate(), dayjs().toDate()],
        "Last 30 Days": [
          dayjs().subtract(29, "days").toDate(),
          dayjs().toDate(),
        ],
        "Last 6 months": [
          dayjs().subtract(6, "month").startOf("month").toDate(),
          dayjs().toDate(),
        ],
        "Last year": [
          dayjs().subtract(1, "year").startOf("year").toDate(),
          dayjs().toDate(),
        ],
        Clear: [null, null],
      },
    };
  },
  methods: {
    /**
     * Used to emit a function to update the date range by which to be sorted
     */
    updateDateRange(value) {
      /**
       * Emits the new date range to be used
       */
      this.$emit("changeDate", value);
    },
  },
  filters: {
    date(val) {
      return val ? dayjs(val).format("YYYY-MM-DD") : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.date-filter {
  min-width: 8rem;
}

::v-deep {
  .reportrange-text {
    border: 0;
  }

  .vue-daterange-picker {
    display: block;
    height: 100%;
  }
}
</style>
