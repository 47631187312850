<template>
  <v-row>
    <v-col class="summary-cards-col">
      <CapacityCard :data="summary" :loading="loading" />
    </v-col>
    <v-col class="summary-cards-col">
      <IssueCountCard
        :data="summary"
        :loading="loading"
        :openIssueTurbineCount="openIssueTurbineCount"
        :turbineCount="turbineCount"
        @goToIssuesTable="goToIssuesTable"
        @goToChecksTab="goToChecksTab"
      />
    </v-col>
    <v-col class="summary-cards-col">
      <AepPotentialCard :data="summary" :loading="loading" />
    </v-col>
    <v-col class="summary-cards-col">
      <GainsFromFixedCard :data="summary" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import { pluralize } from "@/helpers/functions";
import { mapState } from "vuex";
import CapacityCard from "./SummaryCards/CapacityCard.vue";
import IssueCountCard from "./SummaryCards/IssueCountCard.vue";
import AepPotentialCard from "./SummaryCards/AepPotentialCard.vue";
import GainsFromFixedCard from "./SummaryCards/GainsFromFixedCard.vue";

export default {
  name: "SummaryWidget",
  components: {
    CapacityCard,
    IssueCountCard,
    AepPotentialCard,
    GainsFromFixedCard,
  },
  data() {
    return {
      pluralize,
    };
  },
  props: {
    summary: Object,
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    popupLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHomePage: {
      type: Boolean,
      required: false,
      default: false,
    },
    openIssueTurbineCount: {
      type: Number,
      required: false,
      default: null,
    },
    turbineCount: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    goToIssuesTable(type) {
      this.$emit("goToIssuesTable", type, "summary");
    },
    goToChecksTab() {
      this.$emit("goToChecksTab");
    },
  },
};
</script>
<style lang="scss">
.summary-widget {
  .models-text {
    font-size: 1rem;
  }

  .check:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
<style></style>
